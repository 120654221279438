/* @define Display */

@import "../base/global";

.Display,
.Display-link {
  display: block;
  color: var(--color);
  font-weight: bold;
  font-size: inherit;
  line-height: 1.2;
}

/**
 * Display-intro link
 */ 

.Display--intro,
.Display--intro-link {
  font-size: var(--font-size-heading-intro);
  line-height: var(--font-size-line-height);
  font-weight: bold;
}


.Display--subtitle {
  font-size: var(--font-size-heading-subtitle);
  line-height: 1.25;
  font-weight: bold;

}

.Display--intro-link {
  color: var(--link-color);
}

.Display--link:hover {
  color: #fff;
  background: var(--color);
}

/**
 * Display-intro link
 */ 


.Display--1 {
  font-size: var(--font-size-heading);
  line-height: 0.95;
}

.Display--footer {
  font-size: var(--font-size-heading-footer);
  line-height: 0.9;
}

/**
 * Display link
 */

.Display-link {
  display: inline-block;
  position: relative;
  color: var(--link-color);
  font-weight: bold;
  color: var(--link-color);
  text-decoration: none;
  padding: 0.02em 0.08em 0em;
  margin: 0 -0.08em;
}

.Display-link:hover {
  color: #fff;
  background: var(--color);
}

@media (min-width: 400px) {
.Display--intro,
.Display--intro-link {
  max-width:17em;
  line-height: 1.1;
}
}

@media (min-width: 600px) {

.Display--intro,
.Display--intro-link {
  max-width:19em;
  line-height: 1.1;
}
  
}

@media (min-width: 1000px) {
.Display--subtitle {
  max-width:20em;
  line-height: 1.15;

}

.Display--intro,
.Display--intro-link {
  max-width:20em;
  line-height: 1.1;

}
  
}

